<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Location en agence",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Liste",
          active: true,
        },
      ],
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,

      ordersData: [],
      ordersDataLouer: [],
      fields: [
        { key: "model", sortable: true, label: "Model" },
        { key: "marque", sortable: true, label: "Marque" },
        { key: "immatri", label: "Plaque" },
        { key: "type", sortable: true, label: "Type" },
        { key: "statut", sortable: true, label: "Statut" },
        { key: "motorisation", sortable: true, label: "Moteur" },
        { key: "vidange", sortable: true, label: "Km av. vidange" },
        { key: "date", sortable: true, label: "Créé le" },
        { key: "action", label: "Actions" },
      ],
      fieldsRetour: [
        { key: "marque", sortable: true, label: "Marque" },
        { key: "immatri", label: "Immat." },
        { key: "message", sortable: true },
        { key: "montant", sortable: true },
        { key: "dateReservation", sortable: true, label: "Date reserv." },
        { key: "dateDepart", sortable: true, label: "Départ" },
        { key: "dateRetour", sortable: true, label: "Retour prévue" },
        { key: "action", sortable: true },
      ],
      showDisable: false,
      showDelete: false,
      modalToSave: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      showAddClient: false,
      showAddChauffeur: false,
      showReturnCar: false,
      userToUse: 0,
      client: [
        {
          name: "",
        },
      ],
      chauffeur: [],
      clientSelect: false,
      chauffeurSelect: false,
      kmActu: 0,
      prixByDay: 0,
      infoVehicule: null,
      dateLocation: null,
      dateLocationRetour: null,
      kmRetour: 0,
      montant: 0,
      msgLaisser: "",
      estAgence: false,
      idReserve: null,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/entreprise/location",
        undefined,
        false
      );
      if (data && data.data && data.data.vehiculeActif) {
        const formattedTable = data.data.vehiculeActif.map((vehicule) => {
          return {
            id: vehicule.id,
            model: vehicule.model,
            marque: vehicule.marque,
            immatri: vehicule.immatriculation,
            type: vehicule.types,
            statut: vehicule.statut,
            motorisation: vehicule.motorisation,
            vidange: vehicule.kmAvantVidange,
            date: new Date(vehicule.dateCreation).toLocaleDateString("fr-FR"),
            kmAuCompteur: vehicule.kmAuCompteur,
            prixJournalier: vehicule.prixJournalier,
          };
        });

        this.ordersData = [];
        formattedTable.forEach((element) => {
          if (element.statut == "DISPO AGENCE") {
            this.ordersData.push(element);
          }
          /*if (element.statut == "LOUER") {
            this.ordersDataLouer.push(element);
          }*/
        });
      }

      const dataClient = await apiRequest(
        "GET",
        "admin/entreprise/client",
        undefined,
        false
      );
      if (dataClient && dataClient.data) {
        //console.log("data.data:", data.data);
        const formattedTable = dataClient.data.clientActif.map((user) => {
          return {
            id: user.id,
            nom: user.estEntreprise
              ? user.designation
              : user.nom + " " + user.prenom,
            contact: user.contact,
            type: user.estEntreprise ? "ENTREPRISE" : "PARTICULIER",
          };
        });
        this.client = formattedTable;
      }

      const dataChauffeur = await apiRequest(
        "GET",
        "admin/entreprise/chauffeur",
        undefined,
        false
      );
      if (dataChauffeur && dataChauffeur.data) {
        //console.log("data.data:", dataChauffeur.data);
        const formattedTableC = dataChauffeur.data.chauffeurActif.map(
          (user) => {
            return {
              id: user.id,
              nom: user.nom + " " + user.prenom,
              contact: user.contact,
            };
          }
        );
        this.chauffeur = formattedTableC;
      }

      const historiqueLocation = await apiRequest(
        "GET",
        "admin/entreprise/reservation-agence",
        undefined,
        false
      );

      console.log("historiqueLocation:", historiqueLocation.data);

      if (historiqueLocation && historiqueLocation.data) {
        const locations = historiqueLocation.data.map((location) => {
          return {
            dateReservation: new Date(
              location.dateReservation
            ).toLocaleDateString("fr-FR"),
            dateDepart: new Date(location.dateDepart).toLocaleDateString(
              "fr-FR"
            ),
            dateRetour: new Date(location.dateRetourPrevu).toLocaleDateString(
              "fr-FR"
            ),
            montant: location.fraisReservation,
            message: location.messageLaisser,
            id: location.vehicule.id,
            marque: location.vehicule.marque,
            model: location.vehicule.model,
            immatri: location.vehicule.immatriculation,
            type: location.vehicule.types,
            statut: location.statut,
          };
        });
        this.ordersDataLouer = locations;
        //this.rows = location.lenght;
      }
    },
    showModal(row) {
      //console.log("row:", row);
      this.infoVehicule = row.id;
      this.kmActu = row.kmAuCompteur;
      this.prixByDay = row.prixJournalier;
      this.modalToSave = true;
    },
    async louer() {
      const data = await apiRequest(
        "POST",
        "admin/entreprise/reservation-agence",
        {
          idVehicule: this.infoVehicule,
          fraisReservation: this.prixByDay,
          dateDepart: new Date(this.dateLocation),
          dateRetourPrevu: new Date(this.dateLocationRetour),
          idClient: this.clientSelect.id,
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.position();
        this.init();
        this.modalToSave = false;
      }
    },
    async retourner(row) {
      this.infoVehicule = row.id;

      const data = await apiRequest(
        "GET",
        "admin/entreprise/detail-location/?id=" + this.infoVehicule,
        {},
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        let info = data.data.vehicule;
        //console.log("info:", info);
        this.idReserve = info.reservation.id;
        this.dateLocation = new Date(
          info.reservation.dateDepart
        ).toLocaleDateString("fr-FR");
        this.dateLocationRetour = new Date(
          info.reservation.dateRetourPrevu
        ).toLocaleDateString("fr-FR");
        this.montant = info.reservation.fraisReservation;
        this.msgLaisser = info.reservation.messageLaisser;
        this.estAgence = info.reservation.estManuel;
        this.kmRetour = info.kmAuCompteur;

        this.showReturnCar = true;
      }
    },
    async retournerVehicule() {
      /*console.log("this.idReserve :", this.idReserve);
      console.log("this.estAgence:", this.estAgence);
      console.log("this.msgLaisser:", this.msgLaisser);
      console.log("this.kmRetour:", this.kmRetour);*/

      const data = await apiRequest(
        "POST",
        "admin/entreprise/retourner-agence",
        {
          idReserve: this.idReserve,
          estAgence: this.estAgence,
          msgLaisser: this.msgLaisser,
          kmRetour: this.kmRetour,
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.position();
        this.init();
        this.showReturnCar = false;
      }
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      //console.log("row:", row);
      router.push(`/vehicule/details/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"
                    >Liste des véhicules à louer</a
                  >
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6"></div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="py-2 d-flex justify-content-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <button
                        class="btn btn-success"
                        @click="showModal(row.item)"
                      >
                        Louer
                      </button>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"
                    >Liste des véhicules à retourner</a
                  >
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6"></div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="ordersDataLouer"
                    :fields="fieldsRetour"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(action)="row">
                      <button
                        v-if="row.item.statut === 'ACTIF'"
                        class="btn btn-danger"
                        @click="retourner(row.item)"
                      >
                        Retourner
                      </button>
                      <button v-else class="btn btn-info" disabled>
                        Terminer
                      </button>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- Ajout de client -->
    <b-modal
      id="modal-center"
      centered
      v-model="showAddClient"
      title="Ajout de client"
      title-class="font-18"
      hide-footer
    >
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Particulier</a>
          </template>
          <form>
            <div class="mt-2">
              <div class="row">
                <div class="col-md-6">
                  <label for="">Nom</label>
                  <input type="text" class="form-control" />
                </div>
                <div class="col-md-6">
                  <label for="">Prénom</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="">Adresse</label>
                  <input type="text" class="form-control" />
                </div>
                <div class="col-md-6">
                  <label for="">Contact</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="float-right mt-4">
                <button class="btn btn-primary">Ajouter</button>
              </div>
            </div>
          </form>
        </b-tab>
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Entreprise</a>
          </template>
          <form>
            <div class="mt-2">
              <div class="row">
                <div class="col-md-6">
                  <label for="">Nom</label>
                  <input type="text" class="form-control" />
                </div>
                <div class="col-md-6">
                  <label for="">Prénom</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="">Adresse</label>
                  <input type="text" class="form-control" />
                </div>
                <div class="col-md-6">
                  <label for="">Contact</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label for="">IFU</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="float-right mt-4">
                <button class="btn btn-primary">Ajouter</button>
              </div>
            </div>
          </form>
        </b-tab>
      </b-tabs>
    </b-modal>
    <!-- FIN -->

    <!-- Ajout de chauffeur -->
    <b-modal
      id="modal-center"
      centered
      v-model="showAddChauffeur"
      title="Ajout de chauffeur"
      title-class="font-18"
      hide-footer
    >
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Chauffeur</a>
          </template>
          <form>
            <div class="mt-2">
              <div class="row">
                <div class="col-md-6">
                  <label for="">Nom</label>
                  <input type="text" class="form-control" />
                </div>
                <div class="col-md-6">
                  <label for="">Prénom</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="">Adresse</label>
                  <input type="text" class="form-control" />
                </div>
                <div class="col-md-6">
                  <label for="">Contact</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label for="">Email</label>
                  <input type="email" class="form-control" />
                </div>
              </div>
              <div class="float-right mt-4">
                <button class="btn btn-primary">Ajouter</button>
              </div>
            </div>
          </form>
        </b-tab>
      </b-tabs>
    </b-modal>
    <!-- FIN -->

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucessEdit"
      title="Utilisateur modifié avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="success" @click="showSucessEdit = false"
          >OK</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchecEdit"
      title="La modification a échouée"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecEdit = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <!-- LOUER UN VEHICULE -->
    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="modalToSave"
      title="Louer un véhicule"
      title-class="font-18"
      hide-footer
    >
      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="louer"
      >
        <div class="row">
          <div class="col-md-6">
            <label for="">Nom & Prénom du client</label>
            <multiselect
              v-model="clientSelect"
              :options="client"
              label="nom"
              track-by="id"
            ></multiselect>
          </div>
          <div class="col-md-6">
            <label for="">Chauffeur</label>
            <multiselect
              v-model="chauffeurSelect"
              :options="chauffeur"
              label="nom"
              track-by="id"
            ></multiselect>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Date de location</label>
            <input
              type="date"
              class="form-control"
              v-model="dateLocation"
              id=""
            />
          </div>
          <div class="col-md-6">
            <label for="">Date prévue de retour</label>
            <input
              type="date"
              class="form-control"
              v-model="dateLocationRetour"
              id=""
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Prix de location par jour</label>
            <input type="text" class="form-control" v-model="prixByDay" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Kilométrage actuel</label>
            <input type="text" class="form-control" v-model="kmActu" />
          </div>
        </div>
        <button class="btn btn-success float-right mt-4" type="submit">
          Louer
        </button>
      </form>
    </b-modal>
    <!-- FIN LOCATION -->

    <!-- RETOURNER UN VEHICULE -->
    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showReturnCar"
      title="Retourner un véhicule"
      title-class="font-18"
      hide-footer
    >
      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="retournerVehicule"
      >
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Date de location</label>
            <input
              type="text"
              class="form-control"
              disabled
              v-model="dateLocation"
              id=""
            />
          </div>
          <div class="col-md-6">
            <label for="">Date prévue de retour</label>
            <input
              type="text"
              disabled
              class="form-control"
              v-model="dateLocationRetour"
              id=""
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Kilométrage retour</label>
            <input type="text" class="form-control" v-model="kmRetour" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Montant</label>
            <input
              type="text"
              class="form-control"
              id=""
              v-model="montant"
              disabled
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <label>Commentaire</label>
            <textarea class="form-control" v-model="msgLaisser"></textarea>
          </div>
        </div>

        <button class="btn btn-success float-right mt-4" type="submit">
          Retourner
        </button>
      </form>
    </b-modal>
    <!-- FIN RETOUR VEHICULE -->
  </Layout>
</template>
